import React, { useState, useRef } from 'react';
import { QrCodeScanner as QrCodeScannerIcon, Edit as EditIcon } from "@mui/icons-material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MainPagination from '../general/MainPagination';
import QRCode from "react-qr-code";
import AdvertisementService from '../../../services/advertisement';
import { formatDate } from '../../../utils/function';
import { Global } from '../../../utils/general';
import { Toast } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import html2canvas from "html2canvas";
import { IconButton } from '@mui/material';

const BodyAdvertisements = (props) => {
  const { setTitleModalLoader, setOpenModalLoader, handleOpenModal, getAdvertisements, records, totalPages, page, setPage } = props;

  const urlAdvertisement = Global.urlAdvertisement;
  const [selectedQR, setSelectedQR] = useState(null);

  const downloadAdvertisement = async (fileName, nombre) => {
    try {
      setOpenModalLoader(true);
      setTitleModalLoader("Buscando archivo ...");
      const body = {
        advertisement: fileName
      }
      const result = await AdvertisementService.downloadAdvertisement(body);
      if (result.status === CODES.SUCCESS_200) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${nombre}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error al descargar el archivo o documento no disponible.'
        });
      }
    } catch (error) {
      console.log(`Error al descargar el archivo: ${error}`);
    } finally {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
    }
  }

  const qrRef = useRef(null);
  const downloadQR = async (event) => {
    event.stopPropagation();
    if (qrRef.current) {
      const canvas = await html2canvas(qrRef.current);
      const image = canvas.toDataURL("image/png");

      // Crear enlace de descarga
      const link = document.createElement("a");
      link.href = image;
      link.download = "QR_Code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const deleteAdvertisement = async (id_anuncio) => {
    if (!id_anuncio) {
      Toast.fire({
        icon: "info",
        title: "El anuncio aun no esta creado.",
      });
      return;
    }
    const result = await Toast.fire({
      icon: 'question',
      title: '¿Seguro de desactivar el anuncio?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      confirmButtonColor: "#218838",
      cancelButtonColor: "#dc3545",
      timer: 10000,
      timerProgressBar: true,
    });

    if (result.isConfirmed) {
      setOpenModalLoader(true);
      setTitleModalLoader("Desactivando el anuncio ...");
      try {
        const result = await AdvertisementService.deleteAdvertisement(id_anuncio);
        if (result.status === CODES.DELETE_204) {
          Toast.fire({
            icon: 'success',
            title: 'Anuncio desactivado correctamente'
          });
          getAdvertisements();
        } else {
          Toast.fire({
            icon: 'error',
            title: 'No se pudo desactivar el anuncio. Inténtalo nuevamente'
          });
        }
        return [];
      } catch (error) {
        console.log(`Error en eliminarMof: ${error}`);
      } finally {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
    }
  };

  return (
    <div className="container mt-3">
      <MainPagination
        size={'small'}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
      />
      <div className="row">
        {records?.map((ad, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
            <div className={`card text-center p-2 shadow-sm h-100 ${+ad.estado_id !== 1 ? "bg-danger bg-opacity-10 border-danger" : ""}`}>
              <div className="mt-auto d-flex align-items-center justify-content-center gap-3">
                <button type="button" class="btn btn btn-outline-success btn-sm"
                  onClick={() => downloadAdvertisement(ad.ruta, ad.nombre)}
                  style={{
                    padding: 0,
                    cursor: +ad.estado_id === 1 ? "pointer" : "not-allowed",
                    opacity: +ad.estado_id === 1 ? 1 : 0.5,
                  }}
                >
                  <CloudDownloadIcon fontSize="medium" />
                </button>
                {(+ad.estado_id === 1) &&
                  <>
                    <button type="button" class="btn btn btn-outline-dark btn-sm"
                      onClick={+ad.estado_id === 1 ? () => setSelectedQR(`${urlAdvertisement}/${ad.ruta}`) : null}
                      style={{
                        padding: 0,
                        cursor: +ad.estado_id === 1 ? "pointer" : "not-allowed",
                        opacity: +ad.estado_id === 1 ? 1 : 0.5,
                      }}
                    >
                      <QrCodeScannerIcon fontSize="medium" />
                    </button>
                    <button type="button" class="btn btn btn-outline-primary btn-sm"
                      onClick={() => handleOpenModal(ad)}
                      style={{
                        padding: 0,
                        cursor: +ad.estado_id === 1 ? "pointer" : "not-allowed",
                        opacity: +ad.estado_id === 1 ? 1 : 0.5,
                      }}
                    >
                      <EditIcon fontSize="medium" />
                    </button>
                    <button type="button" class="btn btn btn-outline-danger btn-sm"
                      onClick={() => deleteAdvertisement(ad?.id_anuncio)}
                      style={{
                        padding: 0,
                        cursor: +ad.estado_id === 1 ? "pointer" : "not-allowed",
                        opacity: +ad.estado_id === 1 ? 1 : 0.5,
                      }}
                    >
                      <DeleteForeverIcon fontSize="medium" />
                    </button>
                  </>
                }
              </div>
              <p className="text-muted small mb-2 mt-2" style={{ fontSize: "small" }}>{ad.nombre}</p>
              <div className="mt-auto">
                <p className="small mb-1" style={{ fontSize: "small" }}>
                  {ad.trabajador}
                </p>
                <p className="small mb-0" style={{ fontSize: "small" }}>
                  {formatDate(new Date(ad.fechaMod))}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedQR && (
        <div className="qr-overlay" onClick={() => setSelectedQR(null)}>
          <div className="qr-container" style={{ cursor: "pointer" }} ref={qrRef} onClick={(e) => e.stopPropagation()}>
            <QRCode size={200} value={selectedQR} onClick={downloadQR} />
          </div>
        </div>
      )}

    </div>
  );
}
export default BodyAdvertisements;