
import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CODES } from "../../../utils/responseCodes";
import { Toast } from "../../../utils/alerts";
import MofService from "../../../services/mof";
import LinearProgress from '@mui/material/LinearProgress';


const columns = [
  { id: "", label: "#", minWidth: 50, align: "left" },
  { id: "codMof", label: "Codigo", minWidth: 150, align: "left" },
  { id: "cliente.empresa.desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "cliente.desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "puesto.desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 100, align: "left" },
  { id: "porcentaje", label: "Porcentaje", minWidth: 150, align: "left" },
  { id: "trabajador", label: "Ult. Modifiación", minWidth: 200, align: "left" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
];


const columns2 = [
  { id: "", label: "#", minWidth: 50, align: "left" },
  { id: "codMof", label: "MOF", minWidth: 150, align: "left" },
  { id: "desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 150, align: "left" },
  { id: "porcentaje", label: "Porcentaje", minWidth: 150, align: "left" },
  { id: "trabajador", label: "Ult. Modifiación", minWidth: 200, align: "left" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
];

const BodyMof = (props) => {
  const { handleOpen, setOpenModalLoader, setTitleModalLoader, listaMof, setListaMof, listarMof, getDatosMof, setPuestoId,
    setCompanieId, setClienteId, setMofId, idPerfil,
    setOpcionVer, setType, getAprobado, idUsuario, usuaruioEditor } = props;

  const url = "'" + window.location.hash.replace("#", "") + "'";





  const eliminarMof = async (id_mof) => {
    try {
      const result = await Toast.fire({
        icon: 'question',
        title: '¿Estás seguro que deseas eliminar este MOF?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      });

      if (result.isConfirmed) {
        setOpenModalLoader(true);
        try {
          const result = await MofService.eliminarMof(id_mof);
          if (result.status === CODES.SUCCESS_200) {
            Toast.fire({
              icon: 'success',
              title: result.data.message || 'MOF Eliminado'
            });
            setListaMof(result.data.listaMof);
            listarMof();
          } else {
            Toast.fire({
              icon: 'error',
              title: (result?.response?.data?.message || 'Error, no se eliminó el MOF')
            });
          }
          return [];
        } catch (error) {
          console.log(`Error en eliminarMof: ${error}`);
        } finally {
          setOpenModalLoader(false);

        }
      } else {
        // El usuario canceló la eliminación
        Toast.fire({
          icon: 'info',
          title: 'La eliminación del MOF ha sido cancelada.'
        });
      }
    } catch (error) {
      console.log(`Error en eliminarMof: ${error}`);
    }
  };

  const exportMof = async (id_cliente, id_puesto, desEmpresa, desCliente, desPuesto, id_mof, codMof) => {

    try {
      setTitleModalLoader('Generando MOF en excel...');
      setOpenModalLoader(true);
      const result = await MofService.exportMof(url, {
        id_cliente: id_cliente,
        id_puesto: id_puesto,
        id_mof: id_mof
      });
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = codMof + "_" + desEmpresa + "_" + desCliente + "_" + desPuesto + ".xlsx";
        link.click();
        URL.revokeObjectURL(url);
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return []
    } catch (error) {
      console.log(`Error en generateSede: ${error}`);
    }
  }

  const getProgressColor = (percentage) => {
    if (percentage <= 30) {
      return "error"; 
    } else if (percentage >= 31 && percentage <= 80) {
      return "warning"; 
    } else {
      return "success"; 
    }
  };

  // if (idPerfil === 16 || idPerfil === 1 || idPerfil === 23 || usuaruioEditor?.some(user => +user?.usuario_id === idUsuario)) {
  if (usuaruioEditor?.some(user => +user?.usuario_id === idUsuario)) {
    return (
      <div className="page-body-main">
        <div className="page-body__table" style={{ overflow: "hidden" }}>
          <div className="page-body-table">
            <TableContainer className="table-height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaMof?.map((record, indexRow) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                      {columns.map((column, indexColumn) => (
                        <TableCell key={"col" + indexColumn} align={column.align}>
                          {column.id === "" ? indexRow + 1 :
                            column.id === "porcentaje" ? (
                              <div style={{ width: '100%' }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={Number(record?.porcentaje)}
                                  color={getProgressColor(Number(record?.porcentaje))} 
                                  style={{ height: 8 }} 
                                />
                                <span style={{ marginLeft: 10 }}>{Number(record?.porcentaje).toFixed(2)}%</span>
                              </div>
                            ) :
                              column.id === "" ? indexRow + 1 :
                                column.id === "desEstado" ?
                                  <span style={{ color: record.desEstado === 'derivado' ? 'red' : record.desEstado === 'terminado' ? 'green' : 'inherit' }}>
                                    {eval(`record.${column.id}`)}
                                  </span>
                                  :
                                  column.id === "actions" ?
                                    <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                                      <IconButton size="small" title="Ver Mof"
                                        onClick={() => {
                                         
                                          getAprobado(record.id_mof)
                                          setPuestoId(record.puesto_id);
                                          setCompanieId(record.cliente.empresa_id);
                                          setClienteId(record.cliente_id);
                                          setMofId(record.id_mof);
                                          handleOpen();
                                          setOpcionVer(5);
                                          getDatosMof(record.id_mof);
                                        }}
                                      >
                                        <VisibilityIcon color="info" fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => {
                                          getDatosMof(record.id_mof);
                                          getAprobado(record.id_mof)
                                          setPuestoId(record.puesto_id);
                                          setCompanieId(record.cliente.empresa_id);
                                          setClienteId(record.cliente_id);
                                          setMofId(record.id_mof);
                                          handleOpen();
                                          setOpcionVer(6);
                                          setType(1);
                                        }}
                                        title="Editar Mof"
                                      >
                                        <EditIcon color="warning" fontSize="small" />
                                      </IconButton>
                                      <IconButton size="small" title="Descargar Mof Excel"
                                        onClick={() => {
                                          exportMof(
                                            record.cliente.id_cliente,
                                            record.puesto_id,
                                            record.cliente.empresa.desEmpresa,
                                            record.cliente.desCliente,
                                            record.puesto.desPuesto,
                                            record.id_mof,
                                            record.codMof)
                                            ;

                                        }}
                                      >
                                        <FileDownloadIcon color="success" fontSize="small" />
                                      </IconButton>
                                      <IconButton size="small" title="Eliminar Mof"
                                        onClick={() => {
                                          eliminarMof(record.id_mof)
                                        }}
                                      >
                                        <DeleteForeverIcon color="error" fontSize="small" />
                                      </IconButton>
                                    </ButtonGroup>
                                    : eval(`record.${column.id}`)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page-body-main">
        <div className="page-body__table" style={{ overflow: "hidden" }}>
          <div className="page-body-table">
            <TableContainer className="table-height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column2) => (
                      <TableCell key={column2.id} align={column2.align} style={{ minWidth: column2.minWidth }}>
                        {column2.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Mapear los elementos de mofxUsuario y mostrar los valores en la tabla */}
                  {listaMof && listaMof.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.codMof}</TableCell>
                      <TableCell>{row.cliente.empresa.desEmpresa}</TableCell>
                      <TableCell>{row.cliente.desCliente}</TableCell>
                      <TableCell>{row.puesto.desPuesto}</TableCell>
                      <TableCell style={{ color: row.desEstado === 'Proceso' ? 'red' : row.desEstado === 'Terminado' ? 'green' : 'inherit' }}>
                        {row.desEstado}
                      </TableCell>
                      <TableCell>
                        <div style={{ width: '100%' }}>
                          <LinearProgress
                            variant="determinate"
                            value={Number(row?.porcentaje)}
                            color={getProgressColor(Number(row?.porcentaje))} 
                            style={{ height: 8 }} 
                          />
                          <span style={{ marginLeft: 10 }}>{Number(row?.porcentaje).toFixed(2)}%</span>
                        </div>
                      </TableCell>
                      <TableCell>{row.trabajador}</TableCell>
                      <TableCell>
                        <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                          {+row.permiso_id === 1 && (
                            <>
                              <IconButton size="small" title="Ver Mof"
                                onClick={() => {
                                 
                                  setPuestoId(row.puesto_id);
                                  setCompanieId(row.cliente.empresa_id);
                                  setClienteId(row.cliente_id);
                                  setMofId(row.id_mof);
                                  handleOpen();
                                  setOpcionVer(5);
                                  getDatosMof(row.id_mof);
                                }}
                              >
                                <VisibilityIcon color="info" fontSize="small" />
                              </IconButton>
                              <IconButton size="small" title="Descargar Mof Excel" onClick={() => {
                                exportMof(row.cliente_id, row.puesto_id, row.cliente.empresa.desEmpresa, row.cliente.desCliente, row.puesto.desPuesto, row.id_mof, row.codMof);
                              }}
                              >
                                <FileDownloadIcon color="success" fontSize="small" />
                              </IconButton>
                            </>
                          )}

                          {+row.permiso_id === 2 && (
                            <>
                              <IconButton size="small" title="Ver Mof"
                                onClick={() => {
                                 
                                  setPuestoId(row.puesto_id);
                                  setCompanieId(row.cliente.empresa_id);
                                  setClienteId(row.cliente_id);
                                  setMofId(row.id_mof);
                                  handleOpen();
                                  setOpcionVer(5);
                                  getDatosMof(row.id_mof);
                                }}
                              >
                                <VisibilityIcon color="info" fontSize="small" />
                              </IconButton>
                              <IconButton size="small" title="Editar Mof"
                                onClick={() => {
                                  setPuestoId(row.puesto_id);
                                  setCompanieId(row.cliente.empresa_id);
                                  setClienteId(row.cliente_id);
                                  setMofId(row.id_mof);
                                  handleOpen();
                                  setOpcionVer(6)
                                  getDatosMof(row.id_mof);
                                }}

                              >
                                <EditIcon color="warning" fontSize="small" />
                              </IconButton>
                              <IconButton size="small" title="Descargar Mof Excel"
                                onClick={() => {
                                  exportMof(
                                    row.cliente_id,
                                    row.puesto_id,
                                    row.cliente.empresa.desEmpresa,
                                    row.cliente.desCliente,
                                    row.puesto.desPuesto, row.id_mof,
                                    row.codMof);
                                }}
                              >
                                <FileDownloadIcon color="success" fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }

};


export default BodyMof;
