import React from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Button, } from '@mui/material';
import { Input } from "antd";
import { Toast } from "../../../../utils/alerts";
import _ from "lodash";

const EditInfoAdvertisements = ({ infoAdvertisement, setInfoAdvertisement }) => {
    const maxNombre = 200;
    const maxNombreCorto = 50;

    const handleChargeFile = async (e) => {
        let updInfoAdvertisement = _.cloneDeep(infoAdvertisement);
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === "application/pdf") {
            updInfoAdvertisement.file = selectedFile;
            updInfoAdvertisement.nameFile = selectedFile.name;
            if (!updInfoAdvertisement?.id_anuncio) {
                updInfoAdvertisement.nombre = selectedFile.name.substring(0, maxNombre);
                updInfoAdvertisement.nombreCorto = selectedFile.name.substring(0, maxNombreCorto);
            }
            setInfoAdvertisement(updInfoAdvertisement);
        } else {
            Toast.fire({
                icon: "info",
                title: "Solo se permiten archivos PDF.",
            });
        }
    };

    return (
        <div className="row">
            {/* Columna 1: Nombre y Nombre Corto */}
            <div className="col-md-6">
                <div className="group">
                    <div className="contenido" style={{ width: "100%", margin: "0px" }}>
                        <label className="label" style={{ fontSize: "small", top: "-2px" }}>
                            {`Nombre (${maxNombre} max.)`}
                        </label>
                        <Input
                            size="small"
                            placeholder="Nombre del documento"
                            type="text"
                            value={infoAdvertisement?.nombre}
                            onChange={(event) =>
                                setInfoAdvertisement({
                                    ...infoAdvertisement,
                                    nombre: event.target.value,
                                })
                            }
                            maxLength={maxNombre}
                        />
                    </div>
                </div>
                <div className="group mt-2">
                    <div className="contenido" style={{ width: "100%", margin: "0px" }}>
                        <label className="label" style={{ fontSize: "small", top: "-2px" }}>
                            {`Nombre Corto (${maxNombreCorto} max.)`}
                        </label>
                        <Input
                            size="small"
                            placeholder="Nombre corto para el documento"
                            type="text"
                            value={infoAdvertisement?.nombreCorto}
                            onChange={(event) =>
                                setInfoAdvertisement({
                                    ...infoAdvertisement,
                                    nombreCorto: event.target.value,
                                })
                            }
                            maxLength={maxNombreCorto}
                        />
                    </div>
                </div>
            </div>

            {/* Columna 2: Contenedor de PDF con botones */}
            <div className="col-md-6 d-flex align-items-center">
                <div className="group-upload" style={{ width: "100%" }}>
                    <div className="group-upload__contenido d-flex flex-column align-items-center">
                        {infoAdvertisement?.file === null ? (
                            <Button
                                size="small"
                                variant="outlined"
                                endIcon={<CloudUploadIcon />}
                                component="label"
                                sx={{ borderStyle: "dashed" }}
                            >
                                Subir Documento
                                <input
                                    hidden
                                    accept=".pdf"
                                    type="file"
                                    onChange={handleChargeFile}
                                />
                            </Button>
                        ) : (
                            <div
                                className="pdf-preview"
                                style={{
                                    width: "100%",
                                    height: "250px",
                                    border: "1px solid #ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    position: "relative",
                                    flexDirection: "column",
                                }}
                            >
                                <iframe
                                    src={infoAdvertisement?.file instanceof File ? URL.createObjectURL(infoAdvertisement.file) : ""}
                                    width="100%"
                                    height="100%"
                                    style={{ pointerEvents: "none" }}
                                    title={infoAdvertisement?.nombre || ""}
                                ></iframe>

                                {/* Nombre del archivo */}
                                <div
                                    title={infoAdvertisement?.nameFile}  // Muestra el nombre completo al pasar el mouse
                                    style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        background: "rgba(0,0,0,0.6)",
                                        color: "#fff",
                                        padding: "4px 8px",
                                        fontSize: "12px",
                                        borderRadius: "4px",
                                        maxWidth: "80%", // Ajustamos el ancho máximo
                                        whiteSpace: "nowrap", // Evita que el texto se divida en varias líneas
                                        overflow: "hidden", // Oculta el texto que excede el ancho del contenedor
                                        textOverflow: "ellipsis", // Agrega los puntos suspensivos si el texto es muy largo
                                    }}
                                >
                                    {infoAdvertisement?.nameFile}
                                </div>

                                {/* Botones de acción */}
                                <div style={{ position: "absolute", bottom: "10px", display: "flex", gap: "10px" }}>
                                    {/* 👁 Botón para abrir PDF */}
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => window.open(URL.createObjectURL(infoAdvertisement?.file), "_blank")}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>

                                    {/* 🗑 Botón para eliminar archivo */}
                                    <IconButton
                                        size="small"
                                        color="error"
                                        onClick={() => {
                                            setInfoAdvertisement({
                                                ...infoAdvertisement,
                                                "nameFile": null,
                                                "file": null,
                                            })
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditInfoAdvertisements;
