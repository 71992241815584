import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainButton from '../../components/general/MainButton';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import MofService from "../../../services/mof";
import GeneralService from "../../../services/general";
import { CODES } from "../../../utils/responseCodes";
import { Toast } from "../../../utils/alerts";
import { Select } from 'antd';

const { Option } = Select;

const ReportePlanillaSST = ({ userToken }) => {
  const url = "'" + window.location.hash.replace("#", "") + "'";
  const theme = useTheme();
  const currentYearMonth = new Date().toISOString().slice(0, 7); // "YYYY-MM"

  const [companies, setCompanies] = useState(null);
  const [infoCompany, setInfoCompany] = useState(null);
  const [clients, setClients] = useState(null);
  const [infoClient, setInfoClient] = useState(null);
  const [cecos, setCecos] = useState(null);
  const [infoCeco, setInfoCeco] = useState(null);


  // Estados separados para año y mes
  const [dateStart, setDateStart] = useState(currentYearMonth);
  const [year, setYear] = useState(currentYearMonth.slice(0, 4)); // "YYYY"
  const [month, setMonth] = useState(currentYearMonth.slice(5, 7)); // "MM"


  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);


  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (infoCompany?.value) getClientesCecoByEmpresa();
    setInfoClient(null);
    setCecos(null);
  }, [infoCompany?.value]);

  useEffect(() => {
    if (infoClient?.value) getCecosByCliente();
    setInfoCeco(null);
  }, [infoClient?.value]);


  const handleDateChange = (event) => {
    const selectedDate = event.target.value; // "YYYY-MM"
    setDateStart(selectedDate);
    setYear(selectedDate.slice(0, 4));
    setMonth(selectedDate.slice(5, 7));
  };

  const exportPlanillaSST = async () => {
    try {
      setTitleModalLoader('Generando Reporte Planilla SST');
      setOpenModalLoader(true);

      const result = await MofService.exportPlanillaSST(url, {
        anio: year,
        mes: month,
        id_empresa: infoCompany?.value ?? "",
        id_cliente: infoClient?.value ?? "",
        ceco: infoCeco?.value ?? ""
      });

      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `Reporte Planilla SST_${year}${month}.xlsx`;
        link.click();
        URL.revokeObjectURL(fileUrl);
      }

      setOpenModalLoader(false);
    } catch (error) {
      console.log(`Error en exportPlanillaSST: ${error}`);
      setOpenModalLoader(false);
      setTitleModalLoader(null);
    }
  };

  const init = () => {
    Promise.all([getEmpresasSghh()]).then(() => setOpenModalLoader(false));
  };

  const getEmpresasSghh = async () => {
    await GeneralService.getEmpresasSghh()
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          result.data?.forEach((element) => {
            element.id = element.idempresa;
            element.value = element.idempresa;
            element.label = element.nombre;
          });
          setCompanies(result.data);
        } else {
          Toast.fire({ icon: "info", title: "No hay información de empresas" });
        }
      })
      .catch((err) => {
        console.log("getEmpresas", err);
      });
  };

  const getClientesCecoByEmpresa = async () => {
    setOpenModalLoader(true);
    await GeneralService.getClientesCecoByEmpresa(infoCompany?.value)
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          const formattedClients = [];
          result.data?.forEach((element) => {
            if (!formattedClients.some((c) => c.value === element.idcliente)) {
              formattedClients.push({
                id: element.idcliente,
                value: element.idcliente,
                label: element.cliente,
              });
            }
          });
          setClients(formattedClients);
          if (formattedClients.length === 0) {
            Toast.fire({ icon: "warning", title: "La empresa seleccionada no tiene clientes." });
          }
        }
      })
      .catch((err) => {
        console.log("getClientesCecoByEmpresa", err);
      })
      .finally(() => setOpenModalLoader(false));
  };

  const getCecosByCliente = () => {
    setOpenModalLoader(true);
    GeneralService.getClientesCecoByEmpresa(infoCompany?.value)
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          const filteredCecos = result.data
            .filter((element) => element.idcliente === infoClient?.value)
            .map((element) => ({
              id: element.idagrupamientocompañia.trim(),
              value: element.idagrupamientocompañia.trim(),
              label: element.agrupamientocompañia,
            }));
          setCecos(filteredCecos);
          if (filteredCecos.length === 0) {
            Toast.fire({ icon: "warning", title: "El cliente seleccionado no tiene Cecos." });
          }
        }
      })
      .catch((err) => {
        console.log("getCecosByCliente", err);
      })
      .finally(() => setOpenModalLoader(false));
  };

  const filterOption = (input, option) => {
    if (option && typeof option.children === "object" && option.children) {
      return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else if (option && typeof option.children === "string") {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: "fit-content", // Ajusta el ancho al contenido
          maxWidth: "100%", // Evita que se desborde fuera del viewport
          textAlign: "center",
          borderRadius: 3,
          overflow: "hidden", // Evita que los elementos internos sobrepasen el contenedor
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <fieldset>
              <legend style={{ fontSize: "medium" }}>Empresas</legend>
              <Select
                style={{ width: "95%" }}
                showSearch
                value={infoCompany?.value || ""}
                onChange={(value, option) => setInfoCompany(option)}
                filterOption={filterOption}
              >
                <Option value={""}><em>Seleccionar empresa</em></Option>
                {companies?.map((opt) => (
                  <Option key={"comp" + opt.id} value={opt.id}>{opt.label}</Option>
                ))}
              </Select>
            </fieldset>
          </Grid>

          <Grid item xs={12}>
            <fieldset>
              <legend style={{ fontSize: "medium" }}>Clientes</legend>
              <Select
                style={{ width: "95%" }}
                showSearch
                value={infoClient?.value || ""}
                onChange={(value, option) => setInfoClient(option)}
                disabled={!clients}
                filterOption={filterOption}
              >
                <Option value={""}><em>Seleccionar cliente</em></Option>
                {clients?.map((opt) => (
                  <Option key={"cli" + opt.id} value={opt.id}>{opt.label}</Option>
                ))}
              </Select>
            </fieldset>
          </Grid>

          <Grid item xs={12}>
            <fieldset>
              <legend style={{ fontSize: "medium" }}>Ceco</legend>
              <Select
                style={{ width: "95%" }}
                showSearch
                value={infoCeco?.value || ""}
                onChange={(value, option) => setInfoCeco(option)}
                disabled={!cecos}
                filterOption={filterOption}
              >
                <Option value={""}><em>Seleccionar Ceco</em></Option>
                {cecos?.map((opt) => (
                  <Option key={"ceco" + opt.id} value={opt.id}>{opt.label}</Option>
                ))}
              </Select>
            </fieldset>
          </Grid>
          <Grid item>
            <label className="label">Periodo (Mes y Año)</label>
            <TextField
              fullWidth
              required
              className="input"
              size="small"
              type="month"
              value={dateStart}
              onChange={handleDateChange}
            />
          </Grid>

          <Grid item xs={12}>
            <MainButton
              title="Generar reporte"
              onClick={exportPlanillaSST}
              color="white"
              background={theme.palette.primary.main}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>

      <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
    </Box>
  );
};

export default ReportePlanillaSST;
