import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Grid } from "@mui/material";
import { Select } from "antd";

const columns = [
  { id: "select", label: "", minWidth: 50, align: "center" },
  { id: "codMof", label: "MOF", minWidth: 150, align: "left" },
  { id: "desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 150, align: "left" }
];

const columnsCopy = [
  { id: "codMof", label: "MOF", minWidth: 150, align: "left" },
  { id: "desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 150, align: "left" }
];
const { Option } = Select;

const BodyCopyMof = ({ infoUsuarioLA,mofxUsuario, searchValue, selected, setSelected, usuarioLA, copyUsuario, setCopyUsuario ,setOpenModalLoader,mofxUsuarioCopy,setMofxUsuarioCopy}) => {
  
  const fullText = infoUsuarioLA?.children; 
  const nombreCompleto = fullText?.split(" / ")[1]; 
 
  const filteredMofxUsuario = mofxUsuario ?
    mofxUsuario.filter((row) =>
      row.codMof.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desEmpresa.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desCliente.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desPuesto.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desEstado.toLowerCase().includes(searchValue.toLowerCase())
    ) : [];


  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(filteredMofxUsuario.map((row) => ({ mof_id: row.mof_id,permiso_id: row.permiso_id })));
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (row) => {
    const selectedIndex = selected.findIndex(item => item.mof_id === row.mof_id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push({ mof_id: row.mof_id, permiso_id: row.permiso_id });
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const filterOption = (input, option) => {
    if (option && typeof option.children === "object" && option.children) {
      return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else if (option && typeof option.children === "string") {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };
  return (
    <Grid container spacing={4} justifyContent="space-between">
      {/* Primera Tabla */}
      <Grid item xs={5.5}>
      <legend>MOFS DE: {nombreCompleto}</legend>
        <TableContainer className="table-height" >
          <Table stickyHeader>
            <TableHead>
            <TableRow>
                  <TableCell align="center">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < filteredMofxUsuario.length}
                      checked={selected.length === filteredMofxUsuario.length}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {columns.slice(1).map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
            </TableHead>
            <TableBody>
              {filteredMofxUsuario.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Checkbox checked={selected.some(item => item.mof_id === row.mof_id)} onChange={() => handleSelect(row)} />
                  </TableCell>
                  <TableCell>{row.codMof}</TableCell>
                  <TableCell>{row.desEmpresa}</TableCell>
                  <TableCell>{row.desCliente}</TableCell>
                  <TableCell>{row.desPuesto}</TableCell>
                  <TableCell>{row.desEstado}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Segunda Tabla */}
      <Grid item xs={5.5}>
        <fieldset>
          <Select
             style={{ width: "100%", fontSize: "small" }}
             size="large"
             className="select"
             mode={"single"}
             popupClassName="my-drop-down"
             showSearch
             filterOption={filterOption}
            value={copyUsuario?.value || ""}
            onChange={(value, option) => setCopyUsuario(option)}
          >
            <Option value="">Seleccionar usuario</Option>
            {usuarioLA.map((opt, idx) => (
              <Option key={`usuario-${idx}`} value={opt.id}>
              {opt.id + " - " + opt.user + " / " + opt.label}
            </Option>
            ))}
          </Select>
        </fieldset>
        <TableContainer className="table-height" >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columnsCopy.map((column) => (
                  <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mofxUsuarioCopy && mofxUsuarioCopy.length > 0 ? (
                mofxUsuarioCopy.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.codMof}</TableCell>
                    <TableCell>{row.desEmpresa}</TableCell>
                    <TableCell>{row.desCliente}</TableCell>
                    <TableCell>{row.desPuesto}</TableCell>
                    <TableCell>{row.desEstado}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columnsCopy.length} align="center">
                    No hay datos disponibles
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default BodyCopyMof;
