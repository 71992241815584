import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AddAdvertisements from './AddAdvertisements/AddAdvertisements';
import AdvertisementService from '../../../services/advertisement';
import BodyAdvertisements from '../../components/Advertisements/BodyAdvertisements';
import { getDateOL } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/general/SpinnerLoader';

const newAdvertisements = { "nombre": "", "nombreCorto": "", "nameFile": null, "file": null, }

const Advertisements = ({ }) => {
    const [titleModalLoader, setTitleModalLoader] = useState('');
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Obtener el año y el mes actuales
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const [dateStart, setDateStart] = useState(getDateOL(firstDayOfMonth, 'short'));
    const [dateEnd, setDateEnd] = useState(getDateOL(lastDayOfMonth, 'short'));

    const [infoAdvertisement, setInfoAdvertisement] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [nombre, setNombre] = useState('');
    const [trabajador, setTrabajador] = useState('');
    const [dataAdvertisements, setDataAdvertisements] = useState(null);

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        if (page) {
            getAdvertisements();
        }
        init();
    }, [page])

    useEffect(() => {
        if ((!dateStart || dateStart === "") ||
            (!dateEnd || dateEnd === "")) {
            setDataAdvertisements([]);
            setTotalPages(1);
        }
    }, [dateStart, dateEnd])

    const init = async () => {
        Promise.all([
            getAdvertisements(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getAdvertisements = async () => {
        try {
            setOpenModalLoader(true);
            setTitleModalLoader("Buscando anuncios ...");

            setDataAdvertisements([]);
            setTotalPages(1);
            const result = await AdvertisementService.getAdvertisements({ page: page, fechaIni: dateStart, fechaFin: dateEnd, nombre: nombre, trabajador: trabajador});
            if (result.status === CODES.SUCCESS_200) {
                setTotalPages(result.data.last_page)
                setDataAdvertisements(result.data.data)
            }
            setOpenModalLoader(false);
            setTitleModalLoader("");
        } catch (error) {
            console.log(`Error en getEmpresas: ${error}`);
        }
    };

    const handleOpenModal = (info) => {
        const mergedInfo = { ...newAdvertisements, ...info };
        handleOpen();
        setInfoAdvertisement(mergedInfo)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddAdvertisements
                open={open}
                handleClose={handleClose}
                setTitleModalLoader={setTitleModalLoader}
                setOpenModalLoader={setOpenModalLoader}
                getAdvertisements={getAdvertisements}
                infoAdvertisement={infoAdvertisement}
                setInfoAdvertisement={setInfoAdvertisement}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small', }}>Fecha Inicio</label>
                            <input type="date" className="input mt-1" aria-describedby="Fecha inicial"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small', }}
                                value={dateStart} onChange={(e) => setDateStart(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small', }}>Fecha Fin</label>
                            <input type="date" className="input mt-1" aria-describedby="Fecha final"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small', }}
                                value={dateEnd} onChange={(e) => setDateEnd(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} display={'flex'} justifyContent={'center'}>
                    <div className="group" style={{ width: '100%' }}>
                        <div className="contenido">
                            <TextField 
                                id="input-trabajador"
                                variant="outlined"
                                label="Ingrese un trabajador"
                                size='small'
                                sx={{ paddingBottom: 1 }}
                                onChange={(e) => setTrabajador(e.target.value)}
                                fullWidth
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} display={'flex'} justifyContent={'center'}>
                    <div className="group" style={{ width: '100%' }}>
                        <div className="contenido" >
                            <TextField 
                                variant="outlined"
                                label="Ingrese un archivo"
                                size='small'
                                sx={{ paddingBottom: 1 }}
                                onChange={(e) => setNombre(e.target.value)}
                                fullWidth
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={1} display={'flex'} justifyContent={'flex-start'} gap={1}>
                    <button
                        onClick={() => handleOpenModal()}
                        className='btn btn-outline-success btn-sm'
                    >
                        <AddToPhotosIcon />
                    </button>
                    <button
                        style={{ width: '100%' }}
                        onClick={getAdvertisements}
                        className='btn btn-outline-dark btn-sm'
                    >
                        Buscar
                    </button>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={10} sm={9}>
                    <BodyAdvertisements
                        setTitleModalLoader={setTitleModalLoader}
                        setOpenModalLoader={setOpenModalLoader}
                        records={dataAdvertisements}
                        handleOpenModal={handleOpenModal}
                        getAdvertisements={getAdvertisements}
                        totalPages={totalPages}
                        page={page}
                        setPage={setPage}
                    />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Box>
    );
};

export default Advertisements;