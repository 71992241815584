import React, { useState, useEffect } from "react";
import { Box, Grid, MenuItem, TextField, Tooltip } from "@mui/material";
import SpinnerLoader from "../../components/general/SpinnerLoader";
import GeneralService from "../../../services/general";
import PuestoService from "../../../services/puesto";
import MofService from "../../../services/mof";
import { CODES } from "../../../utils/responseCodes";
import { Toast } from "../../../utils/alerts";
import BodyMof from "../../components/MofV2/BodyMof";
import MainButton from "../../components/general/MainButton";
import theme from "../../../assets/themeConfig";
import AddRegisterMof from "./AddMof/AddRegisterMof";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import _ from "lodash";
import BodyAssignMof from "../../components/MofV2/BodyAssignMof";
import AssignMof from "./AssignMof/AssignMof";
import { Select, Input } from "antd";
import MainPagination from "../../components/general/MainPagination";
import CopyMof from "./CopyMof/CopyMof";
const { Option } = Select;

const Mof = ({ userToken }) => {
  const url = "'" + window.location.hash.replace("#", "") + "'";
  const idUsuario = userToken?.user.id_usuario;
  const idPerfil = userToken?.user.perfil.id_perfil;
  const [openModalLoader, setOpenModalLoader] = useState(true);
  const [titleModalLoader, setTitleModalLoader] = useState("");

  //********************* */
  const [open, setOpen] = useState(false);
  const [opcionVer, setOpcionVer] = useState(null);
  const [personaAprobacion, setPersonaAprobacion] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchValueListarMof, setSearchValueListarMof] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);


  const [companies, setCompanies] = useState(null);
  const [clients, setClients] = useState(null);
  const [puesto, setPuesto] = useState(null);
  const [estado, setEstado] = useState(null);
  const [usuarioLA, setUsuarioLA] = useState(null);
  const [puestoCliente, setPuestoCliente] = useState(null);
  const [infoCompany, setInfoCompany] = useState(null);
  const [infoClient, setInfoClient] = useState(null);
  const [infoPuesto, setInfoPuesto] = useState(null);
  const [infoEstado, setInfoEstado] = useState(null);
  const [infoConformidad, setInfoConformidad] = useState(null);
  const [infoUsuarioLA, setInfoUsuarioLA] = useState(null);

  const [datosMof, setDatosMof] = useState([]);

  const [listaMof, setListaMof] = useState(null);
  const [mofxUsuario, setMofxUsuario] = useState(null);
  const [mofxUsuarioBody, setMofxUsuarioBody] = useState(null);
  const [puestoId, setPuestoId] = useState(null); // Estado para almacenar el puesto_id
  const [companieId, setCompanieId] = useState(null);// esto solo para llenar la empresa para el mof editar y registrar
  const [clienteId, setClienteId] = useState(null);// esto solo para llenar el cliente s para el mof editar y registrar
  const [mofId, setMofId] = useState(null);// esto para saber que mof editare o si registrare uno nuevo
  const [type, setType] = useState(1);
  const [bodyMof, setBodyMof] = useState(0);

  const [usuaruioEditor, setUsuaruioEditor] = useState(null);
 

  const tabsConfig = [
    { label: "Registrar MOF", condition: true }, // Siempre visible
    { label: "Asignar MOF", condition: [16, 23, 1].includes(idPerfil) },
    //{ label: "Gestionar Usuario", condition: [1].includes(idPerfil) },
  ];

  const handleChangeBody = (event, newValue) => {
    setBodyMof(newValue);
    setInfoUsuarioLA(null);
    setInfoCompany(null);
    setInfoClient(null);
    setInfoPuesto(null);
    setMofxUsuario(null);
    setType(1);
    // setSearchValue(null);
    setListaMof(null);
    listarMof();

  };

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    Promise.all([getEmpresas(), getPuestos(), listarMof(), getUsuarioLA(), getEstado(), getUsuarioEditor()]).then(() =>
      setOpenModalLoader(false)
    );
  };




  useEffect(() => {
    if (infoCompany?.value) getClientesByEmpresa();
    setInfoClient(null);
  }, [infoCompany?.value]);


  useEffect(() => {
    if (infoUsuarioLA != null) {
      getUsuarioxMof();
      setMofxUsuario(null);
    }
  }, [infoUsuarioLA]);

  useEffect(() => {
    if (idUsuario) {
      getUsuarioxMofBody();
      setMofxUsuarioBody(null);
    }
  }, [idUsuario]);

  useEffect(() => {
    if (infoClient?.value)
      getPuestosxCliente();
    setPuestoCliente(null);
  }, [infoClient?.value]);

  useEffect(() => {
    if (page) listarMof();
  }, [page]);


  const filterOption = (input, option) => {
    if (option && typeof option.children === "object" && option.children) {
      return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else if (option && typeof option.children === "string") {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };

  const getDatosMof = async (id_mof) => {
    setOpenModalLoader(true);
    try {
      const result = await MofService.getDatosMof(id_mof);
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "No se encontraron datos del mof",
          });
          return;
        }
        setDatosMof(result.data);
        setOpenModalLoader(false);
      }
    } catch (error) {
      console.log(`Error en getOpciones: ${error}`);
    }
  };

  const getDatosMofArray = async (id_mof) => {
    setOpenModalLoader(true);
    try {
      const promises = id_mof.map(id_mof => MofService.getDatosMof(id_mof));
      const results = await Promise.all(promises);
      const allData = results.flatMap(result => result.status === CODES.SUCCESS_200 ? result.data : []);

      if (allData.length === 0) {
        /*Toast.fire({
          icon: "info",
          title: "No se encontraron datos del mof",
        });*/
      } else {
        setDatosMof(allData);
      }
    } catch (error) {
      console.log(`Error en getDatosMof: ${error}`);
    } finally {
      setOpenModalLoader(false);
    }
  };

  const exportMofMassive = async (searchValueListarMof, infoCompany, infoClient, infoPuesto, infoEstado, infoConformidad) => {
    try {
      setTitleModalLoader('Generando MOF en excel...');
      setOpenModalLoader(true);
      const result = await MofService.exportMofMassive(searchValueListarMof, infoCompany, infoClient, infoPuesto, infoEstado, infoConformidad);
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "LISTA DE MOFS.xlsx";
        link.click();
        URL.revokeObjectURL(url);
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
    } catch (error) {
      console.log(`Error en generateSede: ${error}`);
    }
  }


  const listarMof = async () => {
    setOpenModalLoader(true);
    try {
      const result = await MofService.listarMof(page, searchValueListarMof, infoCompany?.value, infoClient?.value, infoPuesto?.value, infoEstado?.value, infoConformidad);

      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "No Existen MOF Registrados",
          });
          setListaMof(null);
          return;
        }
        setListaMof(result.data.data);
        setTotalPage(result.data.last_page);
      } else {
        Toast.fire({
          icon: 'warning',
          title: result.response.data.error || 'Error no identificado'
        })
      }
    } catch (error) {
      console.log(`Error en getOpciones: ${error}`);
    } finally {
      setOpenModalLoader(false); // Esto se ejecutará sin importar si hay éxito o error
    }
  };

  const getEmpresas = async () => {
    await GeneralService.getEmpresas()
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          result.data?.forEach((element) => {
            element.id = element.id_empresa;
            element.value = element.id_empresa;
            element.label = element.desEmpresa;
          });
          setCompanies(result.data);
        } else {
          Toast.fire({
            icon: "info",
            title: "No hay información de empresas",
          });
        }
      })
      .catch((err) => {
        console.log("getEmpresas", err);
      });
  };
  const getClientesByEmpresa = async () => {
    await GeneralService.getClientesByEmpresa(infoCompany?.value)
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          result.data?.forEach((element) => {
            element.id = element.id_cliente;
            element.value = element.id_cliente;
            element.label = element.desCliente;
          });
          setClients(result.data);
        } else {
          Toast.fire({
            icon: "info",
            title: "No hay información de clientes",
          });
        }
      })
      .catch((err) => {
        console.log("getClientesByEmpresa", err);
      });
  };

  const getEstado = async () => {
    await MofService.getEstado()
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          result.data?.forEach((element) => {
            element.id = element.corr3;
            element.value = element.corr3;
            element.label = element.desc1;
          });
          setEstado(result.data);
        } else {
          Toast.fire({
            icon: "info",
            title: "No hay información estados",
          });
        }
      })
      .catch((err) => {
        console.log("getEstado", err);
      });
  };
  const getPuestos = async () => {

    try {
      const result = await PuestoService.getPuestos();
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "No se encontraron puestos",
          });
          return;
        }
        result.data?.forEach((element) => {
          element.id = element.id_puesto;
          element.value = element.id_puesto;
          element.label = element.desPuesto;
        });
        setPuesto(result.data);
        //-----Aqui termina codigo
      }
    } catch (error) {
      console.log(`Error en getPuestos: ${error}`);
    }
  };

  const getUsuarioLA = async () => {
    setOpenModalLoader(true);
    try {
      const result = await MofService.getUsuarioLA();
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "No se encontraron usuarios",
          });
          return;
        }
        result.data?.forEach((element) => {
          element.id = element.usuario.id_usuario;
          element.value = element.usuario.id_usuario;
          element.user = element.usuario.usuario
          element.label = element.apePaterno + " " + element.apeMaterno + ", " + element.nombres;
        });
        setUsuarioLA(result.data);
        setOpenModalLoader(false);
      }
    } catch (error) {
      console.log(`Error en getUsuarioLA: ${error}`);
    }
  };

  const getUsuarioxMof = async () => {
    setOpenModalLoader(true);
    try {
      const result = await MofService.getUsuarioxMof(infoUsuarioLA?.value);
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "Usuario no tiene MOF Asignados",
          });
          setOpenModalLoader(false);
          return;
        }
        setMofxUsuario(result.data);
        setOpenModalLoader(false);
      }

    } catch (error) {
      console.log(`Error en getUsuarioxMof: ${error}`);
      setOpenModalLoader(false);
    }
  };


  //Esta Funcion es para mostrar los Mof pero cuando no eres Admin
  const getUsuarioxMofBody = async () => {
    setOpenModalLoader(true);
    try {
      const result = await MofService.getUsuarioxMof(idUsuario);
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "Usuario no tiene MOF Asignados",
          });
          setOpenModalLoader(false);
          return;
        }
        setMofxUsuarioBody(result.data);
        setOpenModalLoader(false);
      }
    } catch (error) {
      console.log(`Error en getUsuarioxMof: ${error}`);
      setOpenModalLoader(false);
    }
  };


  const getPuestosxCliente = async () => {
    try {
      const result = await MofService.getPuestosxCliente(infoClient?.value);

      if (result.status === CODES.SUCCESS_200) {
        // Convertir el objeto en un array usando Object.values()
        const puestosArray = Object.values(result.data);

        puestosArray.forEach((element) => {
          element.id = element.id_puesto;
          element.value = element.id_puesto;
          element.label = element.desPuesto;
        });

        setPuestoCliente(puestosArray);
      } else {
        Toast.fire({
          icon: "info",
          title: "No hay información de clientes",
        });
      }
    } catch (err) {
      console.log("getClientesByEmpresa", err);
      Toast.fire({
        icon: "error",
        title: "Error al obtener los puestos del cliente",
      });
    }
  };



  const getAprobado = async (mofId) => {
    await MofService.getAprobado(mofId, idUsuario)
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          setPersonaAprobacion(result.data);
        } else {
          Toast.fire({
            icon: "info",
            title: "No hay información de la persona que aprobó el mof",
          });
        }
      })
      .catch((err) => {
        console.log("getAprobado", err);
      });
  };

  const getUsuarioEditor = async () => {
    //setOpenModalLoader(true);
    try {
      const result = await MofService.getUsuarioEditor();
      if (result.status === CODES.SUCCESS_200) {
        /* if (result?.data?.length === 0) {
           Toast.fire({
             icon: "info",
             title: "No se encontraron datos del mof",
           });
           return;
         }*/
        setUsuaruioEditor(result.data);
        setOpenModalLoader(false);
      }
    } catch (error) {
      console.log(`Error en getUsuarioEditor: ${error}`);
    }
  };


  const createMof = () => {
    setOpcionVer(null);//para habilitar la edicio o creacion
    setType(1);
    getDatosMof(0);
    handleOpen();
  };

  const asignMof = () => {
    if (infoUsuarioLA != null) {
      setType(2);
      handleOpen();
    } else {
      Toast.fire({
        icon: "warning",
        title: "Selecciona un usuario primero",
      });
      return;
    }


  };

  const copyMof = () => {
    if (infoUsuarioLA != null) {
      setType(3);
      handleOpen();
    } else {
      Toast.fire({
        icon: "warning",
        title: "Selecciona un usuario primero",
      });
      return;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>

      {type === 1 && (
        <AddRegisterMof
          open={open}
          handleClose={handleClose}
          setOpenModalLoader={setOpenModalLoader}
          puesto={puesto}
          companies={companies}
          datosMof={datosMof} setDatosMof={setDatosMof}
          puestoId={puestoId} setPuestoId={setPuestoId}
          companieId={companieId} setCompanieId={setCompanieId}
          clienteId={clienteId} setClienteId={setClienteId}
          listarMof={listarMof}
          mofId={mofId} setMofId={setMofId}
          opcionVer={opcionVer}
          mofxUsuarioBody={mofxUsuarioBody}
          idPerfil={idPerfil}
          idUsuario={idUsuario}
          personaAprobacion={personaAprobacion} setPersonaAprobacion={setPersonaAprobacion} getAprobado={getAprobado} userToken={userToken}
          getDatosMof={getDatosMof}

        />
      )}
      {type === 2 && (
        <AssignMof
          type={type}
          setType={setType}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          setOpenModalLoader={setOpenModalLoader}
          companies={companies}
          puesto={puesto}
          infoUsuarioLA={infoUsuarioLA}
          listaMof={listaMof}
          mofxUsuario={mofxUsuario}
          getUsuarioxMof={getUsuarioxMof}
          datosMof={datosMof} setDatosMof={setDatosMof}
          getDatosMofArray={getDatosMofArray}

        />
      )}
      {type === 3 && (
        <CopyMof
          type={type}
          setType={setType}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          setOpenModalLoader={setOpenModalLoader}
          companies={companies}
          puesto={puesto}
          usuarioLA={usuarioLA} setUsuarioLA={setUsuarioLA}
          listaMof={listaMof}
          mofxUsuario={mofxUsuario}
          datosMof={datosMof} setDatosMof={setDatosMof}
          getDatosMofArray={getDatosMofArray}
          searchValue={searchValue}
          infoUsuarioLA={infoUsuarioLA}

        />
      )}
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={bodyMof} onChange={handleChangeBody} centered>
          {tabsConfig
            .filter((tab) => tab.condition)
            .map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
        </Tabs>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={2}

      >
        {bodyMof === 0 && (
          <Grid item xs={12} sm={10} md={10} >
            <Grid container p={2} style={{ border: "1px solid", borderColor: "#DBD7D7FF", borderRadius: "8px" }} >
              <Grid item xs={12} md={6} paddingRight={'1rem'}>
                <legend style={{ fontSize: "medium" }}>Búsqueda</legend>
                <TextField id="input-with-sx" placeholder="Busqueda por Empresa, Cliente o Puesto" variant="standard"
                  style={{ width: "95%", marginBottom: 15, border: "0.0px solid" }}
                  value={searchValueListarMof}

                  onChange={(e) => setSearchValueListarMof(e.target.value)}
                />
              </Grid>
              <Grid item md={3}>
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>Empresas</legend>
                  <Select
                    style={{ width: "95%" }}
                    size="medium"
                    className="select"
                    mode="single"
                    popupClassName="my-drop-down"
                    showSearch
                    value={infoCompany?.value || ""}
                    onChange={(value, option) => {
                      setInfoCompany(option);
                    }}
                    filterOption={filterOption}
                  >
                    <Option value={""}>
                      <em>Seleccionar empresa</em>
                    </Option >
                    {companies?.map((opt) => {
                      return (
                        <Option key={"comp" + opt.id} value={opt.id}>
                          {opt.id + " - " + opt.label}
                        </Option >
                      );
                    })}
                  </Select>
                </fieldset>
              </Grid>
              <Grid item md={3}>
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>Clientes</legend>
                  <Select
                    style={{ width: "95%" }}
                    size="medium"
                    className="select"
                    mode={"single"}
                    popupClassName="my-drop-down"
                    value={infoClient?.value || ""}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, option) => {
                      setInfoClient(option);
                    }}
                  >
                    <Option value={""}>
                      <em>Seleccionar cliente</em>
                    </Option >
                    {clients?.map((opt) => {
                      return (
                        <Option key={"cli" + opt.id} value={opt.id}>
                          {opt.id + " - " + opt.label}
                        </Option >
                      );
                    })}
                  </Select>
                </fieldset>
              </Grid>
              <Grid item md={3}>
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>Puestos</legend>
                  <Select
                    style={{ width: "95%" }}
                    size="medium"
                    className="select"
                    mode={"single"}
                    popupClassName="my-drop-down"
                    value={infoPuesto?.value || ""}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, option) => {
                      setInfoPuesto(option);
                    }}
                  >
                    <Option value={""}>
                      <em>Seleccionar puesto</em>
                    </Option >
                    {puesto?.map((opt) => {
                      return (
                        <Option Item key={"comp" + opt.id} value={opt.id}>
                          {opt.label}
                        </Option >
                      );
                    })}
                  </Select>
                </fieldset>
              </Grid>
              <Grid item md={1.5}>
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>Conformidad</legend>
                  <Select
                    style={{ width: "95%" }}
                    size="medium"
                    className="select"
                    value={infoConformidad || ""}
                    onChange={(value) => {
                      setInfoConformidad(value);
                    }}
                  >
                    <Option value="">
                      <em>Seleccionar conformidad</em>
                    </Option>
                    <Option value="0">Sin conformidad</Option>
                    <Option value="1">Conformidad</Option>
                  </Select>
                </fieldset>
              </Grid>
              <Grid item md={1.5}>
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>Estado</legend>
                  <Select
                    style={{ width: "95%" }}
                    size="medium"
                    className="select"
                    mode={"single"}
                    popupClassName="my-drop-down"
                    value={infoEstado?.value || ""}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, option) => {
                      setInfoEstado(option);
                    }}
                  >
                    <Option value={""}>
                      <em>Seleccionar estado</em>
                    </Option >
                    {estado?.map((opt) => {
                      return (
                        <Option Item key={"est" + opt.id} value={opt.id}>
                          {opt.label}
                        </Option >
                      );
                    })}
                  </Select>
                </fieldset>
              </Grid>
              <Grid item xs={12} md={4} paddingTop={'2rem'} paddingRight={'1rem'}>
                <MainButton
                  onClick={listarMof}
                  title="Buscar MOF"
                  color="info"
                  background={theme.palette.primary.main}
                />
              </Grid>

            </Grid>
            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginTop: "1rem", marginBottom: "1rem", paddingTop: '3rem' }}>
              <Grid item md={4} container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                {[16, 23, 1].includes(idPerfil) && (
                  <MainButton
                    onClick={createMof}
                    title="Registrar MOF"
                    color="info"
                    background={theme.palette.primary.main}
                    sx={{ marginRight: '1rem' }} // Espacio entre botones
                  />
                )}
                 <MainButton
                  onClick={() => exportMofMassive(searchValueListarMof, infoCompany?.value, infoClient?.value, infoPuesto?.value, infoEstado?.value, infoConformidad?.value)}
                  title="Exportar MOF"
                  color="info"
                  background={"#1E5A02FF"}
                />
              </Grid>
            </Grid>
            <BodyMof
              handleOpen={handleOpen}
              setTitleModalLoader={setTitleModalLoader}
              setOpenModalLoader={setOpenModalLoader}
              type={type}
              setType={setType}
              setCompanies={setCompanies}
              setClients={setClients}
              listaMof={listaMof} setListaMof={setListaMof}
              getDatosMof={getDatosMof}
              getAprobado={getAprobado}
              datosMof={datosMof} setDatosMof={setDatosMof}
              setPuestoId={setPuestoId}
              companieId={companieId} setCompanieId={setCompanieId}
              clienteId={clienteId} setClienteId={setClienteId}
              mofId={mofId} setMofId={setMofId}
              mofxUsuarioBody={mofxUsuarioBody}
              idPerfil={idPerfil}
              listarMof={listarMof}
              infoClient={infoClient}
              infoPuesto={infoPuesto}
              infoEstado={infoEstado}
              setOpcionVer={setOpcionVer}
              infoConformidad={infoConformidad}
              idUsuario={idUsuario}
              usuaruioEditor={usuaruioEditor}
            />
            {totalPage > 1 &&
              <MainPagination
                size={'small'}
                totalPages={totalPage}
                page={page}
                setPage={setPage}
              />
            }
          </Grid>
        )}
        {bodyMof === 1 && (
          <Grid item xs={12} sm={10} md={8}>
            <Grid container p={1}>
              <Grid item md={4} >
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>
                    Seleccionar Usuario
                  </legend>
                  <Select
                    style={{ width: "100%", fontSize: "small" }}
                    size="medium"
                    className="select"
                    mode={"single"}
                    popupClassName="my-drop-down"
                    value={infoUsuarioLA?.value || ""}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, option) => {
                      setInfoUsuarioLA(option);
                    }}
                  >
                    <Option value={""}>
                      <em>Seleccionar usuario</em>
                    </Option>
                    {usuarioLA?.map((opt, idx) => {
                      return (
                        <Option key={`usuario-${idx}`} value={opt.id}>
                          {opt.id + " - " + opt.user + " / " + opt.label}
                        </Option>
                      );
                    })}
                  </Select>
                </fieldset>
              </Grid>
              <Grid item xs={12} md={4} paddingTop={'1rem'} paddingLeft={'1rem'} >
                <TextField id="input-with-sx" label="Busqueda por Empresa, Cliente o Puesto" variant="standard"
                  size="medium"
                  style={{ marginBottom: 20, width: "100%", fontSize: "small" }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />

              </Grid>
              <Grid item md={2} sx={{ marginTop: "2rem", paddingLeft: '2rem' }}>
                <MainButton
                  onClick={asignMof}
                  title="Asignar Mof"
                  color="info"
                  background={theme.palette.primary.main}
                //disabled={!infoUsuarioLA}
                />
              </Grid>
              <Grid item md={2} sx={{ marginTop: "2rem", paddingLeft: '2rem' }}>
                <MainButton
                  onClick={copyMof}
                  title="Copiar Mof"
                  color="info"
                  background={'#236305FF'}
                />

              </Grid>
            </Grid>
            <BodyAssignMof
              mofxUsuario={mofxUsuario} setMofxUsuario={setMofxUsuario}
              searchValue={searchValue}
              getUsuarioxMof={getUsuarioxMof}
              setOpenModalLoader={setOpenModalLoader}
            />
          </Grid>
        )}

        {bodyMof === 2 && (
          <Grid item xs={12} sm={10} md={8}>
            <Grid container p={1}>
              <Grid item md={5} >
                <fieldset>
                  <legend style={{ fontSize: "medium" }}>
                    Seleccionar Usuario
                  </legend>
                  <Select
                    style={{ width: "100%", fontSize: "small" }}
                    size="large"
                    className="select"
                    mode={"single"}
                    popupClassName="my-drop-down"
                    value={infoUsuarioLA?.value || ""}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, option) => {
                      setInfoUsuarioLA(option);
                    }}
                  >
                    <Option value={""}>
                      <em>Seleccionar usuario</em>
                    </Option>
                    {usuarioLA?.map((opt, idx) => {
                      return (
                        <Option key={`usuario-${idx}`} value={opt.id}>
                          {opt.id + " - " + opt.user + " / " + opt.label}
                        </Option>
                      );
                    })}
                  </Select>
                </fieldset>
              </Grid>
            </Grid>
            <BodyAssignMof
              mofxUsuario={mofxUsuario} setMofxUsuario={setMofxUsuario}
              searchValue={searchValue}
              getUsuarioxMof={getUsuarioxMof}
              setOpenModalLoader={setOpenModalLoader}
            />
          </Grid>
        )}
      </Grid>
      <SpinnerLoader open={openModalLoader} />
    </Box>
  );
};

export default Mof;
