import React from "react";
import { CardHeader, CardContent, CardActions } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import EditInfoAdvertisements from "../../../components/Advertisements/AddAdvertisements/EditInfoAdvertisements";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AdvertisementService from "../../../../services/advertisement";
import { Modal } from "antd";
import { Toast } from "../../../../utils/alerts";
import { CODES } from "../../../../utils/responseCodes";

const AddAdvertisements = (props) => {
  const { open, handleClose, setTitleModalLoader, setOpenModalLoader, getAdvertisements, infoAdvertisement, setInfoAdvertisement, } = props;

  const cleanInfo = () => {
    setInfoAdvertisement(null);
    handleClose();
  };

  const saveAdvertisements = async (e) => {
    e.preventDefault();
    console.log(infoAdvertisement)
    if (infoAdvertisement.nombre === "" || infoAdvertisement.nombreCorto === "") {
      Toast.fire({
        icon: "info",
        title: "Debe ingresar un nombre valido.",
      });
      return;
    }
    if (!infoAdvertisement.file) {
      Toast.fire({
        icon: "info",
        title: "Debe subir un archivo valido.",
      });
      return;
    }

    setOpenModalLoader(true);
    setTitleModalLoader("Guardando ...");
    try {
      let formData = new FormData();
      formData.append("file", infoAdvertisement.file);
      formData.append("nombre", infoAdvertisement.nombre);
      formData.append("nombreCorto", infoAdvertisement.nombreCorto);
      if (infoAdvertisement.id_anuncio) formData.append("id_anuncio", infoAdvertisement.id_anuncio);
      if (infoAdvertisement.estado_id) formData.append("estado_id", infoAdvertisement.estado_id);

      const result = await AdvertisementService.saveAdvertisements(formData);
      if (result.status === CODES.CREATE_201) {
        Toast.fire({
          icon: "success",
          title: result.data.message || "Exito en la actualización",
        });
        cleanInfo();
        getAdvertisements();
      } else {
        Toast.fire({
          icon: "error",
          title:
            result?.response?.data?.message ||
            "Error al actualizar el servicio!",
        });
      }
      return [];
    } catch (error) {
      console.log(`Error en saveAdvertisements: ${error}`);
    } finally {
      setOpenModalLoader(false);
      setTitleModalLoader("");
    }
  };

  return (
    <Modal
      title={
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: "#13005A", fontSize: "xx-small" }}
              aria-label="recipe"
            >
              <EngineeringIcon />
            </Avatar>
          }
          title={"Datos del Anuncio"}
          subheader={"Informacion complementaria"}
          sx={{
            paddingBottom: "0.3rem",
            paddingTop: 0,
            margin: "-0.5rem -1rem -0.1rem -1rem",
            borderBottom: `solid 0.05rem #D5DBDB`,
          }}
        />
      }
      open={open}
      onOk={cleanInfo}
      onCancel={cleanInfo}
      destroyOnClose={true}
      footer={null}
      style={{ top: "10%" }}
      width={500}
    >
      <form onSubmit={saveAdvertisements}>
        <CardContent sx={{ padding: 0, margin: "0 -0.5rem" }}>
          <EditInfoAdvertisements
            infoAdvertisement={infoAdvertisement}
            setInfoAdvertisement={setInfoAdvertisement}
          />
        </CardContent>

        <CardActions
          sx={{
            //paddingBottom: '0.3rem',
            //paddingTop: 0,
            margin: "0.5rem -1rem -1rem -1rem",
            borderTop: `solid 0.05rem #00337C`,
          }}
        >
          <button
            type={"submit"}
            className="btn btn-outline-primary btn-sm"
            style={{ fontSize: "15px" }}
          >
            Guardar
          </button>
          <button
            type={"button"}
            onClick={cleanInfo}
            className="btn btn-outline-danger btn-sm"
            style={{ fontSize: "15px" }}
          >
            Cerrar
          </button>
        </CardActions>
      </form>
    </Modal>
  );
};

export default AddAdvertisements;
