import React, { useState, useEffect } from 'react';
import { CardHeader, CardContent, CardActions, } from '@mui/material';
import MofService from '../../../../services/mof';
import { Modal } from 'antd';
import { Toast } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/responseCodes';
import { Box } from '@mui/system';
import BodyCopyMof from '../../../components/MofV2/BodyCopyMof';

const CopyMof = (props) => {
  const {open, handleClose, setOpenModalLoader,mofxUsuario ,setDatosMof, searchValue,usuarioLA,setUsuarioLA,infoUsuarioLA} = props;
 
  const [selected, setSelected] = useState([]);// mof seleccionados que se copiaran
  const [copyUsuario, setCopyUsuario] = useState(null); // usuario al que se copiara todo los mof
  const [mofxUsuarioCopy, setMofxUsuarioCopy] = useState(null); // lista de mofs ya copiados en el usuario

 
  useEffect(() => {
    if (copyUsuario != null) {
      getUsuarioxMof();
      setMofxUsuarioCopy(null);
    }
  }, [copyUsuario]);

  const getUsuarioxMof = async () => {
    if (!copyUsuario) return; // Evita llamadas innecesarias
    setOpenModalLoader(true);
    try {
      const result = await MofService.getUsuarioxMof(copyUsuario?.value);
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "Usuario no tiene MOF Asignados",
          });
          setOpenModalLoader(false);
          return;
        }
        setMofxUsuarioCopy(result.data);
        setOpenModalLoader(false);
      }

    } catch (error) {
      console.log(`Error en getUsuarioxMof: ${error}`);
      setOpenModalLoader(false);
    }
  };

  const cleanInfo = () => {
    setSelected([]);
    handleClose();
    setDatosMof([]);
    setCopyUsuario(null);
    setMofxUsuarioCopy(null);
  }
 

  const guardado = async () => {
    if (copyUsuario) {
      setOpenModalLoader(true);

      try {
        const result = await MofService.copyMofUsuario(selected, copyUsuario?.value);
        if (result.status === CODES.CREATE_201) {
          Toast.fire({
            icon: 'success',
            title: result.data.message || 'MOf reasignados'
          })
          setSelected([]);
          setDatosMof([]);
          setMofxUsuarioCopy(null);
          await getUsuarioxMof(copyUsuario?.value);
        }
        return [];
      } catch (error) {
        console.log(`Error en guardado: ${error}`);
      } finally {
        setOpenModalLoader(false);
       // getUsuarioxMof();
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: ('Debe elegir un Usuario antes de asignar')
      })
      setOpenModalLoader(false);
    }
  }

  return (
    <Modal
      title={
        <CardHeader
          title={"Reasignando  MOF"}
          subheader={"Asignando MOF a otro usuario  "}
          sx={{
            paddingBottom: "0.3rem",
            paddingTop: 0,
            margin: "-0.5rem -1rem -0.1rem -1rem",
            borderBottom: `solid 0.05rem #D5DBDB`
          }}
        />
      }
      open={open}
      onOk={cleanInfo}
      onCancel={cleanInfo}
      destroyOnClose={true}
      footer={null}
      style={{ top: "10%" }}
      width={"95%"}

    >
      <Box sx={{ overflow: "scroll", p: 2, backgroundColor: "#" }}>
        <form >
          <CardContent
            sx={{
              padding: 0,
              margin: "0 -0.5rem", backgroundColor: ""
            }}
          >
             <BodyCopyMof
              searchValue={searchValue}
              mofxUsuario={mofxUsuario}
              setOpenModalLoader={setOpenModalLoader}
              selected={selected} setSelected={setSelected}
              copyUsuario={copyUsuario} setCopyUsuario={setCopyUsuario}
              usuarioLA={usuarioLA} setUsuarioLA={setUsuarioLA}
              mofxUsuarioCopy={mofxUsuarioCopy} setMofxUsuarioCopy={setMofxUsuarioCopy}
              infoUsuarioLA={infoUsuarioLA}
            />
          </CardContent>
          <CardActions
            sx={{
              margin: "0.5rem -1rem -1rem -1rem",
              borderTop: `solid 0.05rem #00337C`, backgroundColor: ""
            }}
          >
            <button
              type={"button"}
              onClick={guardado}
              className="btn btn-primary btn-sm"
              style={{ fontSize: "15px" }}
            >
              Copiar
            </button>
            <button
              type={"button"}
              onClick={cleanInfo}
              className="btn btn-danger btn-sm"
              style={{ fontSize: "15px" }}
            >
              Cerrar
            </button>
          </CardActions>
        </form>
      </Box>
    </Modal>
  );
};

export default CopyMof;