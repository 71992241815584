import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const fileName = import.meta.url.split("/").pop().split(".")[0];

class AdvertisementService {
    saveAdvertisements(body) {
        const ruta = `${API_URL}/${fileName}`;
        return axios.post(ruta, body, {
            headers: {
                ...headerRequest(),
                "Content-Type": "multipart/form-data",
            },
        }).catch((error) => {
            return error;
        });
    }

    getAdvertisements({ page = 1, fechaIni, fechaFin, nombre, trabajador }) {
        const ruta = `${API_URL}/${fileName}`;
        const params = { page, fechaIni, fechaFin, nombre, trabajador };
        return axios.get(ruta, {
            headers: headerRequest(),
            params: params
        }).catch((error) => {
            return error;
        });
    }

    async downloadAdvertisement(body) {
        const ruta = `${API_URL}/${fileName}/download`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    deleteAdvertisement(id_Advertisement) {
        const ruta = `${API_URL}/${fileName}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
            data: { id_anuncio: id_Advertisement }
        }).catch((error) => {
            return error;
        });
    }
}

export default new AdvertisementService();